import React from 'react';
import Confetti from 'react-confetti';
import { useSelector } from 'react-redux';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import { ReactComponent as WarningWhiteIcon } from 'assets/warning-icon-white.svg';
import { ReactComponent as BarCodeWhiteIcon } from 'assets/bar-code-white-icon.svg';
import { ReactComponent as ApprovationWhiteIcon } from 'assets/approvation-icon-white.svg';
import { ReactComponent as InstitutionWhiteIcon } from 'assets/institution-icon-white.svg';
import { ReactComponent as CarRepair } from 'assets/car_repair.svg';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import ExpansionPanel from 'components/ExpansionPanel';
import Chip from 'modules/financing/components/atoms/Chip';
import creditScoreTypes from 'modules/financing/helpers/creditScoreTypes';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import SubHeader from './components/SubHeader';
import TicketStep from './components/steps/Ticket';
import ApprovalStep from './components/steps/Approval';
import DeliveryDetails from './components/steps/DeliveryDetails';
import FinancingStep from './components/steps/Financing';
import PendenciesStep from './components/steps/Pendencies';
import { InsuranceQuote } from './components/steps/InsuranceQuote';
import DigitalProposalStep from './components/steps/DigitalProposal';
import EvaluationProposalDialog from './components/EvaluationProposalDialog';

import ModalInvoice from 'components/molecules/ModalInvoice/';
import ModalCancelInvoice from 'components/molecules/ModalCancelInvoice';

import useModalStateManager from 'hooks/useModalStateManager';
import ChooseVehicle from 'modules/order/components/atoms/ChooseVehicle';
import { IconContainer, Space, LoadingContainer } from './styles';
import DocumentsStep from './components/steps/DocumentsStep';
import VehicleFormData from 'modules/order/components/atoms/VehicleFormData';
import FinancialAdvance from './components/steps/FinancialAdvance/FinancialAdvanceComponent';
import AddScheduleDelivery from 'modules/delivery/DeliveryKanban/components/addScheduleDelivery';

const CheckoutComponent = ({
  hasBankSlip,
  isFinancing,
  openEvaluationProposalDialog,
  setOpenEvaluationProposalDialog,
  checkoutData,
  loadingOrderStatus,
  hasPendencies,
  confettiRun,
  currentUser,
  proposalDigitalEnabled,
  handleClickDownloadDigitalProposal,
  currentVehicle,
  hasQuiver,
  openSchedulingDeliveryDialog,
  setOpenSchedulingDeliveryDialog,
  proposalSelected,
  getDataDeliveryStep,
  proposalId,
  hasProductProposalDeliveryList,
}) => {
  const StatusPendenciesStep = checkoutData?.status?.proposal && (
    <Chip type={creditScoreTypes[checkoutData?.status?.proposal]?.type}>
      {creditScoreTypes[checkoutData?.status?.proposal]?.name}
    </Chip>
  );
  const StatusFinancingStep = checkoutData?.status?.financing && (
    <Chip type={creditScoreTypes[checkoutData?.status?.financing]?.type}>
      {creditScoreTypes[checkoutData?.status?.financing]?.name}
    </Chip>
  );

  const StatusApprovalStep = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {checkoutData?.status?.approvals && (
        <Chip type={creditScoreTypes[checkoutData?.status?.approvals]?.type}>
          {creditScoreTypes[checkoutData?.status?.approvals]?.name}
        </Chip>
      )}
    </div>
  );

  const modalInvoice = useModalStateManager();
  const modalCancelInvoice = useModalStateManager();

  const { currentDealer } = useSelector(state => state.dealerStore);
  const { financialAdvanceOnDms } = currentDealer;

  return (
    <>
      {modalInvoice?.isOpen && (
        <ModalInvoice
          isOpen={modalInvoice?.isOpen}
          handleOpenCancelInvoce={modalCancelInvoice?.handleOpenModal}
          handleCloseModalInvoce={modalInvoice?.handleCloseModal}
        />
      )}
      {modalCancelInvoice?.isOpen && (
        <ModalCancelInvoice
          open={modalCancelInvoice?.isOpen}
          onClose={modalCancelInvoice?.handleCloseModal}
          handleOpenModalInvoice={modalInvoice?.handleOpenModal}
        />
      )}
      {!!confettiRun && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          run={confettiRun}
        />
      )}
      {loadingOrderStatus ? (
        <LoadingContainer>
          <Progress large />
          Carregando informações de checkout...
        </LoadingContainer>
      ) : (
        <div>
          <SubHeader cancelHandleOpen={modalInvoice?.handleOpenModal} />
          <div style={{ padding: 16 }}>
            {/* CONTAINER Veículo*/}
            <ExpansionPanel
              startExpanded
              panel={{
                title: (
                  <ExpansionPanelTitle
                    title={'Veículo'}
                    IconComponent={
                      <IconContainer>
                        <ApprovationWhiteIcon />
                      </IconContainer>
                    }
                  />
                ),
              }}
            >
              <ChooseVehicle
                currentVehicle={currentVehicle}
                onVehicleChoosen={null}
                isFinished={true}
              />
              <VehicleFormData vehicle={currentVehicle} />
            </ExpansionPanel>

            <Space />

            {/* CONTAINER Adiantamento (sinal)*/}
            {financialAdvanceOnDms && (
              <>
                <ExpansionPanel
                  startExpanded
                  panel={{
                    title: (
                      <ExpansionPanelTitle
                        title={'Adiantamento (sinal)'}
                        IconComponent={
                          <IconContainer>
                            <ApprovationWhiteIcon />
                          </IconContainer>
                        }
                      />
                    ),
                  }}
                >
                  <FinancialAdvance />
                </ExpansionPanel>
                <Space />
              </>
            )}

            {/* CONTAINER Cotação de Seguro*/}
            {hasQuiver && (
              <>
                <ExpansionPanel
                  startExpanded
                  panel={{
                    title: (
                      <ExpansionPanelTitle
                        title={'Cotação do Seguro'}
                        IconComponent={
                          <IconContainer>
                            <ApprovationWhiteIcon />
                          </IconContainer>
                        }
                      />
                    ),
                  }}
                >
                  <InsuranceQuote />
                </ExpansionPanel>

                <Space />
              </>
            )}

            {/* CONTAINER Pendências*/}
            {hasPendencies && (
              <>
                <ExpansionPanel
                  startExpanded
                  panel={{
                    title: (
                      <ExpansionPanelTitle
                        title={'Pendências'}
                        IconComponent={
                          <IconContainer>
                            <WarningWhiteIcon />
                          </IconContainer>
                        }
                        extraButton={StatusPendenciesStep}
                      />
                    ),
                  }}
                >
                  <PendenciesStep />
                </ExpansionPanel>

                <Space />
              </>
            )}
            {/* CONTAINER Financiamento*/}
            {isFinancing && (
              <>
                <ExpansionPanel
                  startExpanded
                  panel={{
                    title: (
                      <ExpansionPanelTitle
                        title={'Financiamento'}
                        IconComponent={
                          <IconContainer>
                            <InstitutionWhiteIcon />
                          </IconContainer>
                        }
                        extraButton={StatusFinancingStep}
                      />
                    ),
                  }}
                >
                  <FinancingStep />
                </ExpansionPanel>

                <Space />
              </>
            )}

            {/* CONTAINER Proposta Digital*/}
            {proposalDigitalEnabled && (
              <>
                <ExpansionPanel
                  startExpanded
                  panel={{
                    title: (
                      <ExpansionPanelTitle
                        title={'Proposta Digital'}
                        IconComponent={
                          <IconContainer>
                            <ApprovationWhiteIcon />
                          </IconContainer>
                        }
                        extraButton={
                          <>
                            <HypertextButton
                              onClick={handleClickDownloadDigitalProposal}
                            >
                              <PrintIcon
                                style={{ fontSize: 18, marginRight: 4 }}
                              />
                              Imprimir Proposta Digital
                            </HypertextButton>
                          </>
                        }
                      />
                    ),
                  }}
                >
                  <DigitalProposalStep
                    isFinancing={isFinancing}
                    financingStatus={
                      creditScoreTypes[checkoutData?.status?.financing]?.type
                    }
                  />
                </ExpansionPanel>

                <Space />
              </>
            )}

            {/* CONTAINER Documentos*/}
            <>
              <DocumentsStep />
            </>

            {/* CONTAINER Aprovação*/}
            <ExpansionPanel
              startExpanded
              panel={{
                title: (
                  <ExpansionPanelTitle
                    title={'Aprovação'}
                    IconComponent={
                      <IconContainer>
                        <ApprovationWhiteIcon />
                      </IconContainer>
                    }
                    extraButton={StatusApprovalStep}
                  />
                ),
              }}
            >
              <ApprovalStep
                currentUser={currentUser}
                setOpenEvaluationProposalDialog={
                  setOpenEvaluationProposalDialog
                }
              />
              <EvaluationProposalDialog
                open={openEvaluationProposalDialog}
                onClose={() => setOpenEvaluationProposalDialog(false)}
              />
            </ExpansionPanel>
            <Space />

            {/* CONTAINER Boleto*/}
            {hasBankSlip && (
              <>
                <ExpansionPanel
                  startExpanded
                  panel={{
                    title: (
                      <ExpansionPanelTitle
                        title={'Boleto'}
                        IconComponent={
                          <IconContainer>
                            <BarCodeWhiteIcon />
                          </IconContainer>
                        }
                      />
                    ),
                  }}
                >
                  <TicketStep />
                </ExpansionPanel>
                <Space />
              </>
            )}
            {/* CONTAINER Dados da entrega*/}
            {hasProductProposalDeliveryList && (
              <ExpansionPanel
                startExpanded
                panel={{
                  title: (
                    <ExpansionPanelTitle
                      title={'Dados da entrega'}
                      IconComponent={
                        <IconContainer>
                          <CarRepair />
                        </IconContainer>
                      }
                      // extraButton={StatusApprovalStep}
                    />
                  ),
                }}
              >
                <DeliveryDetails
                  // currentUser={currentUser}
                  proposalSelected={proposalSelected}
                  setOpenSchedulingDeliveryDialog={
                    setOpenSchedulingDeliveryDialog
                  }
                  getDataDeliveryStep={getDataDeliveryStep}
                />
                {openSchedulingDeliveryDialog && (
                  <AddScheduleDelivery
                    open={openSchedulingDeliveryDialog}
                    onClose={() => setOpenSchedulingDeliveryDialog(false)}
                    proposalSelectedKanban={{ proposal: { id: proposalId } }}
                    refetchData={getDataDeliveryStep}
                  />
                )}
              </ExpansionPanel>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutComponent;
