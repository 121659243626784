import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SearchService from 'services/searchServices';
import AutoComplete from 'components/atoms/AutoComplete';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import OrderServices from 'modules/order/services/OrderServices';
import TextField from 'modules/financing/components/atoms/TextField';
import ChooseVehicle from 'modules/order/components/atoms/ChooseVehicle';
import { ProposalVehicleDto } from 'modules/order/types/ProposalVehicleDto';
import {
  ActionsContent,
  MiniLoading,
  NextPanelButton,
} from 'modules/order/pages/OrderSteps/Proposal/steps/ProposalData/styles';
import {
  SubTitle,
  VehicleDataContent,
  VehicleDataForm,
  WrapperVehicleInfo,
  VehicleInfoTitle,
  VehicleInfoData,
} from './styles';

interface ColorAutoCompleteProps {
  height?: string;
}

interface ColorsData {
  label: string;
  value: string;
}

function VehicleDataComponent({ handleNextPanel, isQueue = false }) {
  const {
    dealerId,
    proposalId,
    currentVehicle,
    isFinished,
    queueData,
  } = useSelector(state => state.order);

  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const [interiorColors, setInteriorColors] = useState<ColorsData[]>([]);
  const [exteriorColors, setExteriorColors] = useState<ColorsData[]>([]);
  const [canEditVin, setCanEditVin] = useState(false);
  const [canEditRenavam, setCanEditRenavam] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [exteriorColorQueue, setExteriorColorQueue] = useState(
    queueData?.trim?.exteriorColor
  );
  const [interiorColorQueue, setInteriorColorQueue] = useState(
    queueData?.trim?.interiorColor
  );
  const [productionYearQueue, setProductionYearQueue] = useState(
    queueData?.trim?.productionYear
  );

  const [modelYearQueue, setModelYearQueue] = useState(
    queueData?.trim?.modelYear
  );

  const saveQueueApplicationVehicle = async () => {
    dispatch(ActionCreators.setCurrentVehicle(currentVehicle));
    dispatch(
      ActionCreators.setQueueData({
        trim: {
          ...queueData.trim,
          id: currentVehicle?.id,
          productionYear: productionYearQueue,
          modelYear: modelYearQueue,
          exteriorColor: exteriorColorQueue,
          interiorColor: interiorColorQueue,
          name: currentVehicle?.fullName,
        },
      })
    );
    handleNextPanel();
  };

  const saveProposalApplicationVehicle = async () => {
    if (isFinished) return handleNextPanel();

    const vehicleDto: ProposalVehicleDto = {
      dealId: currentVehicle?.type == '0km' ? null : currentVehicle?.id,
      trimId:
        currentVehicle?.type == '0km'
          ? currentVehicle?.id
          : currentVehicle?.trimId,
      renavam: currentVehicle?.renavam,
      vin: currentVehicle?.vin,
      productionYear: currentVehicle?.productionYear,
      modelYear: currentVehicle?.modelYear,
      color: currentVehicle?.color,
      interiorColor: currentVehicle?.interiorColor,
      type: currentVehicle?.type === '0km' ? '0km' : 'used',
    };

    setLoadingSave(true);

    const { success, data }: any = await OrderServices.setProposalVehicle(
      proposalId,
      dealerId,
      vehicleDto
    );

    if (success) {
      handleNextPanel();

      //remove no api to call api if user need back to step and try remove vehicle
      delete currentVehicle?.noApi;
      dispatch(ActionCreators.setCurrentVehicle(currentVehicle));

      return setLoadingSave(false);
    }

    const errorMessage =
      data || 'Não foi possível salvar o veículo na proposta!';

    dispatch(UIActionCreators.snackbarManagement('error', errorMessage));

    setLoadingSave(false);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    dispatch(
      ActionCreators.setCurrentVehicle({
        ...currentVehicle,
        [name]: value,
      })
    );
  };

  const handleChangeModelYearQueue = event => {
    setModelYearQueue(event.value);
  };

  const handleChangeProductionYearQueue = event => {
    setProductionYearQueue(event.value);
  };

  const handleChangeExteriorColorQueue = event => {
    const { value } = event.target;
    setExteriorColorQueue(value);
  };

  const handleChangeInteriorColorQueue = event => {
    const { value } = event.target;

    setInteriorColorQueue(value);
  };

  const isEmpty = value => {
    return !value || value === '' || value === 'null';
  };

  const removeVehicleOrder = async (dealId, callback) => {
    try {
      setLoadingSave(true);
      const { success, data }: any = await OrderServices.removeVehicleOrder(
        dealerId,
        proposalId,
        dealId
      );

      if (success) {
        callback();
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Veículo removido com sucesso!'
          )
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            data?.message || 'Não foi remover o veículo do pedido!'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Não foi remover o veículo do pedido!'
        )
      );
    } finally {
      setLoadingSave(false);
    }
  };

  const onVehicleChoosen = choosedVehicle => {
    if (choosedVehicle) {
      setCanEditVin(!isEmpty(choosedVehicle.vin));
      setCanEditRenavam(!isEmpty(choosedVehicle.renavam));

      const vehiclePrice =
        choosedVehicle.type == '0km'
          ? choosedVehicle.price0km
          : choosedVehicle.price;
      const vehicle = {
        ...choosedVehicle,
        price: vehiclePrice,
        vin: isEmpty(choosedVehicle?.vin) ? null : choosedVehicle.vin,
        renavam: isEmpty(choosedVehicle?.renavam)
          ? null
          : choosedVehicle?.renavam,
        noApi: true,
      };

      const vehicleDto: ProposalVehicleDto = {
        dealId: choosedVehicle?.type == '0km' ? null : choosedVehicle?.id,
        trimId:
          choosedVehicle?.type == '0km'
            ? choosedVehicle?.id
            : choosedVehicle?.trimId,
        renavam: choosedVehicle?.renavam,
        vin: choosedVehicle?.vin,
        productionYear: choosedVehicle?.productionYear,
        modelYear: choosedVehicle?.modelYear,
        color: choosedVehicle?.color,
        interiorColor: choosedVehicle?.interiorColor,
        type: choosedVehicle?.type === '0km' ? '0km' : 'used',
      };

      const currentVehiclesOrder = {
        vehiclesOrder: [{ ...vehicleDto }],
        vehicle: { ...vehicleDto },
      };

      dispatch(ActionCreators.resetCurrentVehicle());
      dispatch(ActionCreators.setCurrentVehicle(vehicle));
      dispatch(ActionCreators.setCurrentBuyer(currentVehiclesOrder));
    } else {
      if (currentVehicle.id && !currentVehicle?.noApi && !isQueue) {
        removeVehicleOrder(currentVehicle.id, () =>
          dispatch(ActionCreators.setCurrentVehicle(null))
        );
        return;
      }
      dispatch(ActionCreators.setCurrentVehicle(null));
    }
  };

  const buildProductionYear = () => {
    const currentYear = new Date().getFullYear();
    return [currentYear - 2, currentYear - 1, currentYear, currentYear + 1].map(
      year => ({
        label: year,
        value: year,
      })
    );
  };

  const buildModelYearOptions = productionYear => {
    if (!productionYear) {
      return [];
    }
    if (
      productionYear ===
      buildProductionYear()[buildProductionYear().length - 1]?.value
    ) {
      return [{ label: productionYear, value: productionYear }];
    }
    const nextCurrentYear = productionYear + 1;
    return [
      { label: productionYear, value: productionYear },
      { label: nextCurrentYear, value: nextCurrentYear },
    ];
  };

  const getCurrentVehicleForId = async () => {
    try {
      const { data, success }: any = await SearchService.getVehicleById(
        currentVehicle.id
      );
      if (success) {
        dispatch(ActionCreators.setCurrentVehicle(data));
        return;
      }
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.',
          true
        )
      );
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  useEffect(() => {
    if (isQueue && currentVehicle?.id) {
      getCurrentVehicleForId();
    }
  }, []);

  const queueFinished = ['FINISHED', ''].includes(queueData?.status?.value);

  const showAutoCompleteVehicleColors =
    !!currentDealer && currentDealer.vehicleColorsByDms;

  useEffect(() => {
    if (!showAutoCompleteVehicleColors) return;

    (async function getDmsColors() {
      const [
        interiorColorsResponse,
        exteriorColorsResponse,
      ] = await Promise.all<any>([
        OrderServices.getColorsByDms({ colorChoice: 'interior', dealerId }),
        OrderServices.getColorsByDms({ colorChoice: 'exterior', dealerId }),
      ]);

      interiorColorsResponse?.data?.map(item =>
        setInteriorColors(previousState => [
          ...previousState,
          { label: item.colorName, value: item.colorName },
        ])
      );

      exteriorColorsResponse?.data?.map(item =>
        setExteriorColors(previousState => [
          ...previousState,
          { label: item.colorName, value: item.colorName },
        ])
      );
    })();
  }, []);

  const isVehicle0km = !currentVehicle?.vin?.length;

  const blockToNextStep = () => {
    if (loadingSave) {
      return true;
    }

    if (isQueue) {
      if (!modelYearQueue || !productionYearQueue || !exteriorColorQueue) {
        return true;
      } else {
        return false;
      }
    }

    if (
      isVehicle0km &&
      (!currentVehicle?.productionYear ||
        !currentVehicle?.modelYear ||
        !currentVehicle?.color)
    ) {
      return false;
    }

    return false;
  };

  function ExternalColorAutoComplete({ height }: ColorAutoCompleteProps) {
    const value = isQueue ? exteriorColorQueue : currentVehicle.color;

    const disabled = isQueue
      ? isFinished || queueFinished
      : isFinished || !isEmpty(canEditRenavam);

    const onChange = (value: string | number) => {
      if (isQueue) return handleChangeExteriorColorQueue(value);
      return handleChange({ target: { name: 'color', value } });
    };

    return (
      <AutoComplete
        name="exteriorColor"
        label="Cor Externa*"
        isClearable={false}
        options={exteriorColors}
        value={value ? { label: value, value } : null}
        minHeight={height}
        onChange={(option: ColorsData) => onChange(option.value)}
        disabled={disabled}
      />
    );
  }

  function InternalColorAutoComplete({ height }: ColorAutoCompleteProps) {
    const value = isQueue ? interiorColorQueue : currentVehicle.interiorColor;

    const disabled = isQueue
      ? isFinished || queueFinished
      : isFinished || !isEmpty(canEditRenavam);

    const onChange = (value: string | number) => {
      if (isQueue) return handleChangeInteriorColorQueue(value);
      return handleChange({ target: { name: 'interiorColor', value } });
    };

    return (
      <AutoComplete
        name="interiorColor"
        label="Cor Interna"
        isClearable={true}
        options={interiorColors}
        value={value ? { label: value, value } : null}
        minHeight={height}
        onChange={(option: ColorsData) => onChange(option?.value ?? '')}
        disabled={disabled}
      />
    );
  }

  return (
    <VehicleDataContent>
      <ChooseVehicle
        currentVehicle={currentVehicle}
        onVehicleChoosen={onVehicleChoosen}
        isFinished={isFinished}
        isQueue={isQueue}
      />
      {currentVehicle && (
        <>
          <SubTitle>Informações do veículo</SubTitle>
          {isQueue && (
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <AutoComplete
                  name="productionYear*"
                  label="Ano de Fabricação*"
                  isClearable={false}
                  options={buildProductionYear()}
                  value={
                    productionYearQueue
                      ? {
                          label: productionYearQueue,
                          value: productionYearQueue,
                        }
                      : null
                  }
                  onChange={e => {
                    handleChangeProductionYearQueue(e);
                  }}
                  minHeight="56px"
                  disabled={queueFinished}
                />
              </Grid>
              <Grid item xs={3}>
                <AutoComplete
                  name="modalYear"
                  label="Ano do Modelo*"
                  isClearable={false}
                  options={buildModelYearOptions(productionYearQueue)}
                  value={
                    modelYearQueue
                      ? {
                          label: modelYearQueue,
                          value: modelYearQueue,
                        }
                      : null
                  }
                  onChange={e => {
                    handleChangeModelYearQueue(e);
                  }}
                  minHeight="56px"
                  disabled={!productionYearQueue || queueFinished}
                />
              </Grid>
              <Grid item xs={3}>
                {showAutoCompleteVehicleColors ? (
                  <ExternalColorAutoComplete height="56px" />
                ) : (
                  <TextField
                    label="Cor Externa*"
                    value={exteriorColorQueue}
                    name="exteriorColor"
                    onChange={handleChangeExteriorColorQueue}
                    disabled={isFinished || queueFinished}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                {showAutoCompleteVehicleColors ? (
                  <InternalColorAutoComplete height="56px" />
                ) : (
                  <TextField
                    label="Cor Interna"
                    value={interiorColorQueue}
                    name="interiorColor"
                    onChange={handleChangeInteriorColorQueue}
                    disabled={isFinished || queueFinished}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {!isQueue && !isVehicle0km ? (
            <VehicleDataForm>
              <WrapperVehicleInfo>
                <VehicleInfoTitle>Chassi:</VehicleInfoTitle>
                <VehicleInfoData>{currentVehicle?.vin}</VehicleInfoData>
              </WrapperVehicleInfo>
              <WrapperVehicleInfo>
                <VehicleInfoTitle>Renavam:</VehicleInfoTitle>
                <VehicleInfoData>{currentVehicle.renavam}</VehicleInfoData>
              </WrapperVehicleInfo>
            </VehicleDataForm>
          ) : (
            !isQueue && (
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <AutoComplete
                    name="productionYear"
                    label="Ano de Fabricação"
                    isClearable={false}
                    options={buildProductionYear()}
                    value={
                      currentVehicle?.productionYear
                        ? {
                            label: currentVehicle.productionYear,
                            value: currentVehicle.productionYear,
                          }
                        : null
                    }
                    onChange={e => {
                      handleChange({
                        target: { value: e?.value, name: 'productionYear' },
                      });
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <AutoComplete
                    name="modalYear"
                    label="Ano do Modelo"
                    isClearable={false}
                    options={buildModelYearOptions(
                      currentVehicle?.productionYear
                    )}
                    value={
                      currentVehicle?.modelYear
                        ? {
                            label: currentVehicle.modelYear,
                            value: currentVehicle.modelYear,
                          }
                        : null
                    }
                    onChange={e => {
                      handleChange({
                        target: { value: e?.value, name: 'modelYear' },
                      });
                    }}
                    disabled={!currentVehicle?.productionYear}
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  {showAutoCompleteVehicleColors ? (
                    <ExternalColorAutoComplete />
                  ) : (
                    <TextField
                      name="color"
                      label="Cor Externa"
                      value={currentVehicle.color}
                      onChange={handleChange}
                      disabled={isFinished || canEditVin}
                      required
                      mini
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {showAutoCompleteVehicleColors ? (
                    <InternalColorAutoComplete />
                  ) : (
                    <TextField
                      name="interiorColor"
                      label="Cor Interna"
                      value={currentVehicle.interiorColor}
                      onChange={handleChange}
                      disabled={isFinished || !isEmpty(canEditRenavam)}
                      mini
                    />
                  )}
                </Grid>
              </Grid>
            )
          )}
          {!isFinished && (
            <ActionsContent style={{ marginTop: '10px' }}>
              <NextPanelButton
                disabled={blockToNextStep()}
                onClick={
                  isQueue
                    ? saveQueueApplicationVehicle
                    : saveProposalApplicationVehicle
                }
              >
                Próximo
                {loadingSave && <MiniLoading size={20} />}
              </NextPanelButton>
            </ActionsContent>
          )}
        </>
      )}
    </VehicleDataContent>
  );
}

export default VehicleDataComponent;
