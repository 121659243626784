import { createActions, createReducer } from 'reduxsauce';
import {
  FilterAggregations,
  FilterElement,
} from 'modules/store-inventory/types/Filter';
import { DealPage } from 'modules/store-inventory/types/DealPage';
import { DealItem } from 'modules/store-inventory/types/DealDetail';
import { buildSop, defaultSop } from 'helpers/sop';

export interface StoreInventoryState {
  dealerId?: number | null;
  query: string;
  aggregations: FilterAggregations;
  dealPage: DealPage;
  loadingSearch: boolean;
  loadingSearchMore: boolean;
  dealDetail?: DealItem;
  sop: any;
  activeFiltersArray: FilterElement[];
  openSimulateDrawer: boolean;
  dealToSimulate?: DealItem;
  currentPage: number;
  pageSize: number;
  order: number;
  isMobiOne: boolean;
}

export const INITIAL_STATE = {
  query: '',
  aggregations: {},
  loadingSearch: true,
  loadingSearchMore: false,
  sop: defaultSop,
  activeFiltersArray: [],
  currentPage: 0,
  order: 15,
  pageSize: 50,
  dealPage: {
    results: [],
    numResults: 0,
    page: 0,
  },
  dealerId: null,
  openSimulateDrawer: false,
  isMobiOne: false,
};

export const { Types, Creators } = createActions({
  searchInventory: [],
  searchInventoryMore: [],
  setQueryFilter: ['query'],
  setAggregations: ['aggregations'],
  setDealPage: ['dealPage'],
  setLoadingSearch: ['loading'],
  setLoadingSearchMore: ['loading'],
  setDealDetail: ['deal'],
  setCurrentPage: ['currentPage'],
  setPageSize: ['pageSize'],
  onChangeSop: ['filterKey', 'value', 'filterObject'],
  setOpenSimulateDrawer: ['open', 'deal'],
  setOrderBy: ['order'],
  setIsMobiOne: ['isMobiOne'],
  cleanStore: [],
});

export const setQueryFilter = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    query: action.query,
  };
};

export const setAggregations = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    aggregations: action.aggregations,
  };
};

export const setDealPage = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dealPage: { ...state.dealPage, ...action.dealPage },
  };
};

export const setLoadingSearch = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingSearch: action.loading,
  };
};

export const setLoadingSearchMore = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingSearchMore: action.loading,
  };
};

export const setDealDetail = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dealDetail: action.deal,
  };
};

export const setCurrentPage = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentPage: action.currentPage,
  };
};

export const setPageSize = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    pageSize: action.pageSize,
  };
};

export const onChangeSop = (state = INITIAL_STATE, action) => {
  let arrayTemp = state.activeFiltersArray;
  if (action.filterObject) {
    if (
      state.activeFiltersArray.find(
        filter => filter.id === action.filterObject.id
      )
    ) {
      arrayTemp = [...arrayTemp.filter(el => el.id !== action.filterObject.id)];
    } else {
      arrayTemp.push(action.filterObject);
    }
  }
  if (action.filterKey === 'ops_') {
    return {
      ...state,
      sop: {
        ...state.sop,
        ops_: action.value ? `${action.value}.1` : null,
      },
      activeFiltersArray: arrayTemp,
      currentPage: 0,
    };
  }
  const sopBuilded = buildSop(state.sop, action.filterKey, action.value);

  return {
    ...state,
    sop: { ...sopBuilded },
    activeFiltersArray: arrayTemp,
  };
};

export const setOpenSimulateDrawer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    openSimulateDrawer: action.open,
    dealToSimulate: action.deal,
  };
};

export const setOrderBy = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    order: action.order,
  };
};

export const setIsMobiOne = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMobiOne: action.isMobiOne,
  };
};

export const cleanStore = () => {
  return {
    query: '',
    aggregations: {},
    loadingSearch: true,
    loadingSearchMore: false,
    sop: defaultSop,
    activeFiltersArray: [],
    currentPage: 0,
    order: 0,
    pageSize: 50,
    dealPage: {
      results: [],
      numResults: 0,
      page: 0,
    },
    dealerId: null,
    openSimulateDrawer: false,
    isMobiOne: false,
  };
};

export const HANDLERS = {
  [Types.SET_QUERY_FILTER]: setQueryFilter,
  [Types.SET_AGGREGATIONS]: setAggregations,
  [Types.SET_LOADING_SEARCH]: setLoadingSearch,
  [Types.SET_LOADING_SEARCH_MORE]: setLoadingSearchMore,
  [Types.SET_DEAL_PAGE]: setDealPage,
  [Types.SET_DEAL_DETAIL]: setDealDetail,
  [Types.SET_CURRENT_PAGE]: setCurrentPage,
  [Types.SET_PAGE_SIZE]: setPageSize,
  [Types.ON_CHANGE_SOP]: onChangeSop,
  [Types.SET_OPEN_SIMULATE_DRAWER]: setOpenSimulateDrawer,
  [Types.CLEAN_STORE]: cleanStore,
  [Types.SET_ORDER_BY]: setOrderBy,
  [Types.SET_IS_MOBI_ONE]: setIsMobiOne,
  RESET_ALL_STORE: cleanStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);
