import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import * as API from './api';
import * as DTO from './dto';

interface HandleReturn {
  onError?: () => void;
  onSuccess?: <Response>(data?: Response) => void;
}

interface CustomMutation<Query, Response> {
  handleReturn?: HandleReturn;
  key: string;
  queriesToInvalidate?: string[];
  request: (params: Query) => Promise<Response>;
}

interface CustomQuery<Query, Response> {
  enabled?: boolean;
  key: string;
  params: Query;
  request: (params: Query) => Promise<Response>;
}

function useCustomMutation<Query, Response>({
  handleReturn,
  key,
  request,
  queriesToInvalidate,
}: CustomMutation<Query, Response>) {
  const queryClient = useQueryClient();
  return useMutation([key], (params: Query) => request(params), {
    onError: () => {
      if (handleReturn?.onError) handleReturn?.onError();
    },
    onSuccess: data => {
      if (queriesToInvalidate) {
        queriesToInvalidate.map(query => {
          queryClient.invalidateQueries(query);
        });
      }
      if (handleReturn?.onSuccess) handleReturn?.onSuccess(data);
    },
  });
}

function useCustomQuery<Query, Response>({
  enabled = true,
  key,
  params,
  request,
}: CustomQuery<Query, Response>) {
  return useQuery({
    enabled,
    queryFn: () => request(params),
    queryKey: ([key] as unknown) as QueryKey,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 3000,
  });
}

export function useGetAllInsuranceQuoteByProposalId(
  params: DTO.GetAllInsuranceQuoteByProposalIdQ,
  enabled: boolean
) {
  return useCustomQuery({
    key: `getAllInsuranceQuoteByProposalId-${String(params?.proposalId)}`,
    request: API.getAllInsuranceQuoteByProposalId,
    params,
    enabled,
  });
}

export function useGetBestsInsuranceQuoteByProposalId(
  params: DTO.GetAllInsuranceQuoteByProposalIdQ,
  enabled: boolean
) {
  return useCustomQuery({
    key: `getBestsInsuranceQuoteByProposalId-${String(params?.proposalId)}`,
    request: API.getBestsInsuranceQuoteByProposalId,
    params,
    enabled,
  });
}

export function useStartInsuranceQuote(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'startInsuranceQuote',
    request: API.startInsuranceQuote,
    handleReturn,
  });
}

export function useMarkSelectedInsuranceQuote(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'markSelectedInsuranceQuote',
    request: API.markSelectedInsuranceQuote,
    handleReturn,
  });
}

export function useSendInsuranceQuoteToSpecialist(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'sendInsuranceQuoteToSpecialist',
    request: API.sendInsuranceQuoteToSpecialist,
    handleReturn,
  });
}

export function useSendInsuranceQuoteToCustomer(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'sendInsuranceQuoteToCustomer',
    request: API.sendInsuranceQuoteToCustomer,
    handleReturn,
  });
}
