import { Grid, Button, Radio } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

export const TabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ disabled }) => (disabled ? 0 : '16px 16px 0')};
  gap: 16px;
`;

export const HistoryContainer = styled.div`
  height: calc(100vh - 306px);
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px;
  gap: 16px;
  position: relative;
`;

export const WrapperCalcButton = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleResult = styled.div`
  width: 100%;
  font-size: 16px;
`;

export const GridList = styled(Grid)`
  height: calc(100vh - ${({ disabled }) => (disabled ? '502px' : '460px')});
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid transparent;
`;

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #757575;
  }
  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${({ disabled }) =>
      disabled ? '#757575' : ({ theme }) => theme.palette.primary.main};
  }
`;

export const NumberOfInstallments = styled.div`
  margin: 0px 8px;
  font-weight: 400;
  font-size: 14px;
  min-width: 24px;

  &.details {
    margin: 0px;
  }
`;

export const InstallmentValue = styled.div`
  width: 100%;
  margin-left: 8px;
  font-weight: 700;
  font-size: 14px;
`;

export const TotalAmount = styled.div`
  width: 100%;
  margin: 0px 8px;
  display: flex;
  justify-content: end;
  font-weight: 400;
  font-size: 14px;
  text-align: start;

  &.details {
    margin: 0px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border: 2px solid #e0e0e0;
`;

export const WrapperSubmitButton = styled.div`
  padding: 16px;
  display: ${({ disabled }) => (disabled ? 'none' : 'block')};
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 2px;
`;

export const CardWrapper = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  gap: 16px;
`;

export const CardInfos = styled.div`
  width: 100%;

  &.details {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  & > div {
    margin-bottom: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ContentInfo = styled.div`
  &.bold {
    font-weight: bold;
  }
`;

export const CardButton = styled(Button)`
  align-self: center;
  padding: 16px;
`;

export const ModalContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 48px;
  z-index: 1;
  background-color: #fff;
  height: calc(100vh - 279px);
  visibility: ${({ openModal }) => (openModal ? 'visible' : 'hidden')};
  transform: ${({ openModal }) =>
    openModal ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.2s, visibility 0.2s;
`;

export const StyledBackButton = styled(Button)`
  &.MuiButton-root {
    padding: 0px;
    margin-bottom: 16px;
    &:hover {
      background-color: transparent;
    }
  }
  & > span {
    text-transform: none;
  }
`;

export const ProgressWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: center;
`;

export const TitleDetailsSimulation = styled.div`
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
`;

export const WrapperSimulationDetails = styled.div`
  padding: 16px;
`;

export const ItemDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperResultDetails = styled.div`
  height: calc(100vh - 538px);
`;
