import { useEffect, useState } from 'react';

import useCustomQuery from 'hooks/useCustomQuery';
import useSnackBar from 'hooks/useSnackBar';

import { getDealerConfig } from 'services/dealerConfig';
import DealerServices from 'services/dealerServices';

interface Option {
  label: string;
  value: number;
}

export function useGetNonAttendanceReasons({ dealerId }: { dealerId: number }) {
  const [reasonsOptions, setReasonsOptions] = useState<Option[]>([]);
  const [showNonAttendanceReasons, setShowNonAttendanceReasons] = useState<
    boolean
  >(false);

  const { error } = useSnackBar();

  const getNonAttendanceReasons = async dealerId => {
    try {
      const response = await DealerServices.getNonAttendanceReasons(dealerId);

      if (response?.success) {
        const formatted = response?.data?.map(item => {
          return { label: item.reason, value: item.id };
        });
        return setReasonsOptions(formatted);
      }

      return error('Falha ao carregar motivos de não comparecimento!');
    } catch {
      return error('Desculpe, ocorreu um erro inesperado.');
    }
  };

  const loadCustomAttendanceReasons = async dealerId => {
    try {
      const response = await getDealerConfig(dealerId);

      if (response?.success) {
        const showReasons = !!response?.data?.reasonNonAttendance;
        return setShowNonAttendanceReasons(showReasons);
      }

      return error('Falha ao carregar configurações da loja!');
    } catch {
      return error('Desculpe, ocorreu um erro inesperado.');
    }
  };

  const {
    isFetching: fetchingNonAttendanceReasons,
    isLoading: loadingNonAttendanceReasons,
    refetch: refetchNonAttendanceReasons,
  } = useCustomQuery({
    key: 'getNonAttendanceReasons',
    request: getNonAttendanceReasons,
    params: dealerId,
  });

  const {
    isFetching: fetchingDealerConfig,
    isLoading: loadingDealerConfig,
    refetch: refetchDealerConfig,
  } = useCustomQuery({
    key: 'getDealerConfig',
    request: loadCustomAttendanceReasons,
    params: dealerId,
  });

  useEffect(() => {
    refetchDealerConfig();
  }, []);

  useEffect(() => {
    if (showNonAttendanceReasons) {
      refetchNonAttendanceReasons();
    }
  }, [showNonAttendanceReasons]);

  return {
    reasonsOptions,
    showNonAttendanceReasons,
    loadingDealerConfig: fetchingDealerConfig || loadingDealerConfig,
    loadingReasonsOptions:
      fetchingNonAttendanceReasons || loadingNonAttendanceReasons,
  };
}
