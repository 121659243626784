import React, { memo } from 'react';
import Select from 'react-select';

import { FormHelperText } from '@material-ui/core';

import * as S from './styles';

const AutoComplete = ({
  options,
  name,
  onChange,
  value = undefined,
  label = null,
  defaultValue = null,
  isClearable = true,
  isSearchable = true,
  isLoading = false,
  isRtl = false,
  disabled = false,
  minHeight = '47px',
  isMulti = false,
  width = '',
  error = null,
  helperText = null,
  disabledOptions = [],
  menuPosition = 'fixed',
  noOptionsMessage = () => 'Sem opções disponíveis',
  ...rest
}) => {
  const customStyles = {
    container: styles => ({
      ...styles,
      minHeight,
      maxHeight: '58px',
      width,
    }),
    option: (styles, { data, disabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: '1rem',
        backgroundColor: disabled
          ? undefined
          : isSelected
          ? '#00B0AA'
          : isFocused
          ? '#00b0aa39'
          : undefined,
        color: disabled ? '#ccc' : isSelected ? 'black' : data.color,
        cursor: disabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !disabled
            ? isSelected
              ? data.color
              : '#00B0AA'
            : undefined,
        },
      };
    },
    valueContainer: styles => ({
      ...styles,
      overflowX: 'auto',
      flexWrap: 'initial',
      alignItems: 'end',
      minHeight,
      maxHeight: '58px',
    }),
    multiValueLabel: styles => ({
      ...styles,
      lineHeight: '14px',
    }),
    multiValue: styles => ({
      ...styles,
      minWidth: 'initial',
    }),
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
      fontSize: '1rem',
      alignItems: value ? 'end' : 'center',
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
      },
      height: '100%',
      minHeight,
      maxHeight: '58px',
    }),
    menu: styles => ({
      ...styles,
      zIndex: 9999,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    singleValue: styles => ({
      ...styles,
      lineHeight: '19px',
      top: label ? '30px' : '24px',
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: '1rem',
      whiteSpace: 'nowrap',
    }),
  };

  return (
    <S.Container>
      {!!value && (
        <S.Label error={error} minHeight={minHeight}>
          {label}
        </S.Label>
      )}
      <Select
        value={value}
        placeholder={label}
        styles={customStyles}
        className="basic-single"
        classNamePrefix="select"
        onChange={onChange}
        name={name}
        defaultValue={defaultValue}
        options={(options?.length ? options : [])?.map(option => ({
          ...option,
          isDisabled: disabledOptions?.includes(option?.value),
        }))}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        isMulti={isMulti}
        menuPosition={menuPosition}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={() => 'Carregando...'}
        {...rest}
      />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </S.Container>
  );
};

export default memo(AutoComplete);
